import React, { useState, useEffect } from 'react';
import { RedirectTo } from '../../util/utilLibrary';

import { Col, Row, Alert } from 'react-bootstrap';
import MenuItem from './components/menuitem';
import Layout from '../../components/layoutDefault';
import api from '../../services/api';
import './style.css';


export default function Menu(props) {
    const [menuItens, setMenuItens] = useState([]);
    const [message, setMessage] = useState();
    const [retornoHardLock, setRetornoHardLock] = useState();
    const [exibeMensagemHardLock, setExibeMensagemHardLock] = useState(false);

    let activeItem = '';

    const verificaHardLock = async () => {
        let config = {
            params: {
                'HASH': sessionStorage.getItem("RC_HASH") + Math.random(),
                'ALIAS': window.alias_RC,
            }
        }

        try {
            const response = await api.get('/RetornaMensagemHL', config);

            const dados = response.data;
            if (response.data.CODIGO != "0") {
                setExibeMensagemHardLock(true);
            }

            setRetornoHardLock(dados);
        }
        catch (err) {
            console.log(err.toString());
        }
    }

    useEffect(() => {
        sessionStorage.setItem('RC_APROVACAO_EXIBEMENU', false);
        GetPermissions();
        verificaHardLock();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function GetPermissions() {
        let itens = [];

        const perfil = JSON.parse(sessionStorage.RC_PERFIL);

        if (perfil !== null) {
            if (perfil.APROVACAO === 'T') {
                itens.push(
                    <Col lg="3" className="mt-3" key="aprov">
                        <MenuItem
                            title="Aprovação de Movimento"
                            icon="check"
                            path="/movimentos" />
                    </Col>
                )
                activeItem = "/movimentos";
            }

            if (perfil.SOLICITACAO === 'T') {
                itens.push(
                    <Col lg="3" className="mt-3" key="compras">
                        <MenuItem
                            title="Solicitação de Compras"
                            icon="file-invoice"
                            path="/compras" />
                    </Col>
                )
                activeItem = "/compras";
            }

            // if (perfil.RELATORIO === 'T')

            if (perfil.NOTAFISCAL == 'T') {
                itens.push(
                    <Col lg="3" className="mt-3" key="notafiscal">
                        <MenuItem
                            title="Aprovação Nota Fiscal"
                            // subtitle="Indicadores de Desempenho"
                            icon="sticky-note"
                            path="/notaFiscal" />
                    </Col>
                )
                activeItem = "/notaFiscal";
            }

            if (perfil.FINANCEIRO === 'T') {
                itens.push(
                    <Col lg="3" className="mt-3" key="financeiro">
                        <MenuItem
                            title="Aprovação Financeira"
                            icon="file-invoice-dollar"
                            path="/financeiro" />
                    </Col>
                )
                activeItem = "/financeiro";
            }

            if (perfil.PROGRAMACAO === 'T') {
                itens.push(
                    <Col lg="3" className="mt-3" key="programacoes">
                        <MenuItem
                            title="Programações"
                            icon="calendar-check"
                            path="/programacao" />
                    </Col>
                )
                activeItem = "/programacao";
            }

            if (perfil.RELATORIO === 'T') {
                itens.push(
                    <Col lg="3" className="mt-3" key="kpi">
                        <MenuItem
                            title="KPI's"
                            subtitle="Indicadores de Desempenho"
                            icon="chart-pie"
                            path="/kpi" />
                    </Col>
                )
                activeItem = "/kpi";
            }



            //verifica se existe apenas um item no menu, caso 
            //sim, redireciona automaticamente para o mesmo
            if (itens.length === 1) {
                sessionStorage.setItem('RC_APROVACAO_EXIBEMENU', false);
                RedirectTo(activeItem, props.history);
            }
            else if (itens.length > 1) {
                sessionStorage.setItem('RC_APROVACAO_EXIBEMENU', true);
                setMenuItens(itens);
            }
            else {
                setMessage(
                    <Alert variant="primary" className="text-center">
                        Usuário sem permissão de acesso!
                    </Alert>
                );
            }
        }
        else {
            setMessage(
                <Alert variant="danger">
                    {'ERRO: SEM RETORNO DE PERFIL'};
                </Alert>
            )
        }
    }


    return (
        <Layout>
            <h2 className="pt-5 titulo-menu">Selecione uma opção...</h2>
            <p className="subtitulo-menu"> EMPRESA: {sessionStorage.getItem("RC_CODCOLIGADA") + " - " + sessionStorage.getItem("RC_NOMECOLIGADA")} </p>
            {message ?? <></>}
            {exibeMensagemHardLock && <><Alert variant="danger">HardLock error: {retornoHardLock?.MENSAGEM}</Alert></>}
            <Row>
                {menuItens.length > 0 ? menuItens.map((item, i) => (
                    menuItens[i]
                ))
                    :
                    <></>
                }
            </Row>
        </Layout >
    );
}